html, body {
  margin:0;
  padding:0;
  width:100%;
  height:100%;
  background-color:#ffcccc;
  font-family:'Indie Flower';
}

.spacer {
  width:100%;
  height:12.5em;
}

/* Menu */
.menu {
  position:fixed;
  display:flex;
  width:100%;
  height:150px;
  top:0;
  background-color:#ff8080;
  box-shadow:0 5px 25px black;
  margin-bottom:1em;
}

.menu .content {
  display:flex;
  align-items:center;
}

.menu h1 {
  display:flex;
  text-align:center;
  margin:0.5em;
  font-size:3em;
  color:white;
  text-shadow:3px 3px black;
}

.menu img {
  display:flex;
  height:7em;
  width:7em;
  border-radius:100px;
  margin:0.5em;
  box-shadow:5px 5px 20px black;
}

/* Link Navbar */
.links {
  position:fixed;
  display:flex;
  top:150px;
  justify-content:center;
}

.pageLink {
  color:white;
  text-decoration:none;
  font-size:2em;
  padding:10px;
  text-shadow:0 0 10px black;
  background-color:#a15252;
}

.pageLink:hover {
  background-color:#6b3737;
  text-shadow:0 0 10px #cccccc;
}

.pageLink:active {
  background-color:#ca6868;
  text-shadow:0 0 10px #cccccc;
}

.titleHeader {
  font-size:3em;
  margin:0;
  text-align:center;
  color:white;
  text-shadow:3px 3px black;
}

/* Home Page styling */
main {
  padding:10px;
}

.homeParagraph {
  text-align:center;
  font-size:1.5em;
}

.imagesContainer {
  display:flex;
  justify-content:center;
}

.heart {
  font-size:2em;
  margin:0;
  padding:0;
  color:red;
  text-shadow:3px 3px black;

}

.homeImage {
  border-radius:1000px;
  height:20em;
  width:20em;
  box-shadow:5px 5px 10px black;
  margin:1em;
}